import React from "react";
import { useStaticQuery, graphql, Link } from 'gatsby';

import "../styles/styles.css";

import Layout from "../Components/Layout";

const _ = require('lodash');

export default function Articles() {
    const data = useStaticQuery(graphql`
    query DevLogQuery {
        allMarkdownRemark {
          nodes {
            id
            fileAbsolutePath
            frontmatter {
              title
              date(locale: "")
              slug
              excerpt
              draft
            }
            timeToRead
          }
        }
      }      
    `);
    
    /* 
      TODO: The article filtering and sorting logic needs to be part of graphql.
    */
    const articles = data.allMarkdownRemark.nodes.filter(node => node.fileAbsolutePath.includes("/dev-log/"));

    const enabledArticles = articles.filter(article => !article.frontmatter.draft);

    const chronologicallySortedArticles = _.reverse(_.sortBy(enabledArticles, [function(article) { return article.frontmatter.date; }]));
    
    return <Layout>
        <h1 className="mt-6 font-extrabold md:text-3xl text-2xl"> My Developer Log </h1>

        <h2 className="mt-1 mb-8 font-normal md:text-xl text-lg text-justify"> 
            I document progress on my pet projects here. 
            I hope keeping it public will help me being a little more motivated 😅
        </h2>

        <main>
            {
            chronologicallySortedArticles.map(article => {
                return <article key={article.id}>
                    <h3 className="blog-article-link">
                        <Link to={article.frontmatter.slug}>
                          {article.frontmatter.title}
                        </Link>
                    </h3>
                    <small className="blog-article-meta font-bold">
                      {new Date(article.frontmatter.date).toDateString()} 
                    </small>
                    <p className="mt-1 text-base">{article.frontmatter.excerpt}</p>
                </article>;
            })
            }
        </main>
    </Layout>;
}